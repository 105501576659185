<template>
  <hr />
  <div class="footer">
    concept and design:
    <router-link to="/impressum">Beate-U WG</router-link>
  </div>
</template>

<style>
.footer {
  text-align: center;
  padding: 0.5em;
}
</style>
