<template>
  <p>
    Du hast {{ Object.keys(completed).length }} von {{ tasks.length }} Einträgen
    geschafft
  </p>
</template>

<script>
export default {
  props: {
    tasks: {
      type: Object,
    },
    completed: {
      type: Object,
    },
  },
};
</script>
