import jwt_decode from "jwt-decode";
import router from "../router";

export const handleAuth = {
  data() {
    return {
      accessToken: localStorage.getItem("access_token")
        ? JSON.parse(localStorage.getItem("access_token"))
        : null,
      refreshToken: localStorage.getItem("refresh_token")
        ? JSON.parse(localStorage.getItem("refresh_token"))
        : null,
    };
  },
  methods: {
    async getAccessToken() {
      if (
        !localStorage.getItem("access_token") ||
        jwt_decode(this.accessToken).exp < new Date().getTime() / 1000
      ) {
        try {
          const rawResponse = await fetch(
            process.env.VUE_APP_API_HOST + "/auth/token",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ token: this.refreshToken }),
            }
          );
          if (!rawResponse.ok) throw Error("Some Auth Error");
          const response = await rawResponse.json();
          this.accessToken = response.accessToken;
        } catch (err) {
          return router.push("/errlogout");
        }
      }
      return this.accessToken;
    },
    async login(name, password) {
      const rawResponse = await fetch(
        process.env.VUE_APP_API_HOST + "/auth/login",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: name, password: password }),
        }
      );
      const response = await rawResponse.json();
      this.accessToken = response.accessToken;
      this.refreshToken = response.refreshToken;
    },
    async logout() {
      await fetch(process.env.VUE_APP_API_HOST + "/auth/logout", {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: this.refreshToken }),
      });
      localStorage.clear();
    },
  },
  watch: {
    accessToken: {
      handler() {
        localStorage.setItem("access_token", JSON.stringify(this.accessToken));
      },
      deep: true,
    },
    refreshToken: {
      handler() {
        localStorage.setItem(
          "refresh_token",
          JSON.stringify(this.refreshToken)
        );
      },
      deep: true,
    },
  },
};
