<template>
  <Header />
  <router-view />
  <Footer />
  <Alert
    v-if="updateExists"
    title="Es steht eine neue Version zu verfügung"
    buttonMsg="Update Now"
    :buttonAction="refreshApp"
  />
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Alert from "@/components/Alert.vue";
import updateSW from "@/mixins/updatesw";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Alert,
  },
  mixins: [updateSW],
};
</script>
