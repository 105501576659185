<template>
  <div class="header">
    <nav class="flexbox-container">
      <div class="flexbox-item flexbox-item-left">
        <router-link to="/">{{ appName }}</router-link>
      </div>
      <div class="flexbox-item flexbox-item-right navbar-link">
        <router-link to="/about">About</router-link> |
        <router-link to="/account">Account</router-link>
      </div>
      <div class="flexbox-item flexbox-item-right navbar-burger-menu">
        <fa icon="bars" class="baricon" @click="isOpen = true" />
      </div>
    </nav>
    <hr />
  </div>

  <Slide
    right
    noOverlay
    width="300"
    closeOnNavigation
    :isOpen="isOpen"
    @closeMenu="isOpen = false"
    :burgerIcon="false"
  >
    <span>
      <router-link to="/">Home</router-link>
    </span>
    <span>
      <router-link to="/about">About</router-link>
    </span>
    <span>
      <router-link to="/account">Account</router-link>
    </span>
  </Slide>
</template>

<script>
import { Slide } from "vue3-burger-menu";

export default {
  components: {
    Slide,
  },
  data() {
    return {
      isOpen: false,
      appName: process.env.VUE_APP_NAME,
    };
  },
};
</script>

<style>
.flexbox-container {
  display: flex;
  /* justify-content: space-between; */
}

.flexbox-item {
  display: flex;
  align-items: flex-end;
}

.flexbox-item-left {
  font-size: 2em;
  color: var(--tertiary-color);
  font-weight: bold;
}

.flexbox-item-right {
  padding-bottom: 0.35rem;
  margin-left: auto;
}

.header {
  padding-top: 1rem;
  position: sticky;
  top: 0;
  background-color: var(--background-color);
}

nav a {
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: var(--tertiary-color);
}

.bm-menu a.router-link-exact-active {
  color: var(--tertiary-color);
}

.baricon {
  height: 26px;
  width: 26px;
  display: flex;
}

.navbar-burger-menu {
  display: none;
}

@media only screen and (max-width: 500px) {
  .navbar-burger-menu {
    display: flex;
  }
  .navbar-link {
    display: none;
  }
}

.bm-menu {
  background-color: var(--alt-background-color);
}
</style>
