export const handleTasksData = {
  data() {
    return {
      tasks: localStorage.getItem("task_items")
        ? JSON.parse(localStorage.getItem("task_items"))
        : null,
    };
  },
  mounted() {
    // fetch task data
    fetch(process.env.VUE_APP_API_HOST + "/tasks")
      .then((res) => res.json())
      .then((data) => {
        this.tasks = data;
      });
  },
  watch: {
    tasks: {
      handler() {
        localStorage.setItem("task_items", JSON.stringify(this.tasks));
      },
      deep: true,
    },
  },
};
