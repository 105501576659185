export const handleUserData = {
  data() {
    return {
      completed: localStorage.getItem("completed")
        ? JSON.parse(localStorage.getItem("completed"))
        : {},
    };
  },
  methods: {
    toggleTaskCompletion(taskuuid) {
      this.completed[taskuuid]
        ? delete this.completed[taskuuid]
        : (this.completed[taskuuid] = Date.now());
    },
    getCompletionBool(taskuuid) {
      return this.completed[taskuuid] ? true : false;
    },
    async downloadCompleted(accessToken) {
      if (!localStorage.getItem("refresh_token")) return;
      const rawResponse = await fetch(
        process.env.VUE_APP_API_HOST + "/users/myself",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      const content = await rawResponse.json();
      if (content.completed) this.completed = content.completed;
    },
    async uploadCompleted(accessToken) {
      if (!localStorage.getItem("refresh_token")) return;
      await fetch(process.env.VUE_APP_API_HOST + "/users/myself/completed", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify({ completed: this.completed }),
      });
    },
  },
};
