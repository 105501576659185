<template>
  <div v-if="title || alert" class="alert">
    <span v-if="title" id="title">{{ title }}<br /></span>
    <span v-if="msg" id="msg"> {{ msg }} </span>
    <button v-if="buttonMsg" @click="buttonAction">{{ buttonMsg }}</button>
  </div>
</template>

<script>
export default {
  props: {
    title: null,
    msg: null,
    buttonAction: null,
    buttonMsg: null,
  },
};
</script>

<style scoped>
.alert {
  position: sticky;
  bottom: 0px;
  width: 100%;
  background-color: var(--tertiary-color);
  text-align: center;
  padding: 0.5rem 0;
}

#title {
  font-weight: bold;
}

button {
  background-color: inherit;
  border-style: solid;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
}
</style>
