<template>
  <div v-if="!tasks">lading...</div>
  <div v-if="tasks">
    <TaskCounter :tasks="tasks" :completed="completed" class="noprint" />
    <div v-for="item in tasks" v-bind:key="item.uuid">
      <ListItem
        :task="item.task"
        :votes="item.votes"
        :isCompleted="getCompletionBool(item.uuid)"
        @voteUp="item.votes++"
        @voteDown="item.votes--"
        @completeTask="toggleTaskCompletion(item.uuid)"
      />
    </div>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue";
import TaskCounter from "./TaskCounter.vue";
import { handleTasksData } from "@/mixins/handleTasksData";
import { handleUserData } from "@/mixins/handleUserData";
import { handleAuth } from "@/mixins/handleAuth";

export default {
  components: {
    ListItem,
    TaskCounter,
  },
  mixins: [handleAuth, handleTasksData, handleUserData],
  async mounted() {
    if (!localStorage.getItem("refresh_token")) return;
    this.downloadCompleted(await this.getAccessToken());
  },
  watch: {
    completed: {
      async handler() {
        localStorage.setItem("completed", JSON.stringify(this.completed));
        if (!localStorage.getItem("refresh_token")) return;
        this.uploadCompleted(await this.getAccessToken());
      },
      deep: true,
    },
  },
};
</script>
