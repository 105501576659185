import { createApp } from "vue";
import App from "./App.vue";
import "./assets/global.css";
import { FontAwesomeIcon } from "@/plugins/font-awesome";
import "./registerServiceWorker";
import router from './router'

createApp(App).use(router)
  .component("fa", FontAwesomeIcon)
  .mount("#app");
