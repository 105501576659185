<template>
  <table :class="{ completed: isCompleted }">
    <tr>
      <td class="left">
        <fa
          v-if="isCompleted"
          @click="completeTask"
          type="far"
          icon="check-circle"
          class="icon"
        />
        <fa
          v-else
          @click="completeTask"
          type="far"
          icon="circle"
          class="icon"
        />
      </td>
      <td class="middle">{{ task }}</td>
      <!-- <td class="right">
        <div class="voting">
          <fa @click="voteUp" icon="chevron-up" class="icon" />
          {{ votes }}
          <fa @click="voteDown" icon="chevron-down" class="icon" />
        </div>
      </td> -->
    </tr>
  </table>
</template>

<script>
export default {
  props: ["task", "votes", "isCompleted"],
  methods: {
    voteUp() {
      this.$emit("voteUp");
    },
    voteDown() {
      this.$emit("voteDown");
    },
    completeTask() {
      this.$emit("completeTask");
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  background-color: var(--primary-color);
  margin: 10px 0;
  padding: 5px;
  box-sizing: border-box;
  font-size: 18px;
}

.completed {
  background-color: var(--secondary-color);
}
.completed .middle {
  text-decoration: line-through;
}
.icon {
  max-height: 1em;
  max-width: 1em;
}

.left {
  width: 1.3em;
}
.right {
  width: 1.3em;
}

.voting {
  float: right;
  text-align: center;
}
.voting .icon {
  display: block;
}
</style>
